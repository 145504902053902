import {
  AccessTime,
  ArrowDropDown,
  Cancel,
  Dashboard,
  DateRange,
  DirectionsWalk,
  ExitToApp,
  PictureAsPdf,
} from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/header";
import fetchData from "../opd/fetchData";
import {
  AppointmentData,
  BranchWaitTimes,
  FaultyCheckout,
  Hospital,
  OPDChamberData,
  WalkInData,
} from "../opd/models";
import { getPreBookedAvgCheckedInWait } from "../opd/processor";
import { generateFaultyCheckoutPDF } from "./FaultyCheckoutPDF";
import { generateDoctorAwayPDF } from "./generateDoctorAwayPDF";
import HourlyOPDViewPopup, { HourlyDataItem } from "./HourlyOPDPopup";

const hospitalData: Hospital[] = [
  { id: "eba947d7-6faa-4236-ab61-d442cf54a528", name: "BLK Max Hospital" },
  { id: "7840d8c6-3509-48d6-8ea3-4bed103c6dcc", name: "Lajpat Nagar-MICC" },
  {
    id: "54406929-5705-4308-a843-0082e05a8623",
    name: "Max Medcentre - Lajpat Nagar",
  },
  {
    id: "09ff2a9b-2692-42b1-9a85-79e662e9160b",
    name: "Max Medcentre - Mohali ",
  },
  {
    id: "a7872cf4-25fa-4894-8309-73eb316154fb",
    name: "Max Hospital - Bathinda",
  },
  {
    id: "95eb2416-3919-48a0-9a6c-75da506361c0",
    name: "Max Hospital - Dehradun",
  },
  { id: "05478906-a974-4f78-84a2-38ce85c39206", name: "Max Hospital - Dwarka" },
  {
    id: "bb2a3651-cb02-41aa-9fb3-1d11854973e0",
    name: "Max Hospital - Gurgaon",
  },
  {
    id: "cbb3329e-d89d-4bd2-b64d-e46e20fc018b",
    name: "Max Hospital - Lucknow",
  },
  { id: "28f97deb-01bc-4d87-9234-48411be68ca8", name: "Max Hospital - Mohali" },
  { id: "17d6c290-0f12-4e27-b9e9-09ee2f2598ce", name: "Max Hospital - Nagpur" },
  { id: "62a84dbe-2d9d-458a-9c3c-e3b9f149e92e", name: "Max Hospital - Noida" },
  {
    id: "04b55f72-3aae-4c70-ad6e-3f2d2074c8ea",
    name: "Max Hospital - Panchsheel Park",
  },
  {
    id: "a622baa4-d8e9-45ad-88a7-b3c81a8f3c18",
    name: "Max Hospital - Patparganj",
  },
  {
    id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
    name: "Max Hospital - Saket East",
  },
  {
    id: "73a89ef4-e542-42c1-8189-392f9a1b10a0",
    name: "Max Hospital - Saket Smart",
  },
  {
    id: "3ac21495-2d90-4978-8537-51d7f698d8f3",
    name: "Max Hospital - Saket West",
  },
  {
    id: "62b7ae4f-fed7-43e9-bbfa-39eb8a749e8a",
    name: "Max Hospital - Shalimar Bagh",
  },
  {
    id: "1a1975df-6a3a-4660-b66a-c1b64267ef98",
    name: "Max Hospital - Vaishali",
  },
  { id: "88587db5-8539-4056-ae01-dd249c949a77", name: "Nanavati Max Hospital" },
];

export interface HospitalStats {
  id: string;
  name: string;
  error?: string;
  loading: boolean;
  appointmentData?: AppointmentData;
  walkInData?: WalkInData;
  appointmentDistribution?: {
    time: string;
    percentage: number;
  }[];
  yesterdayAppointmentDistribution?: {
    time: string;
    percentage: number;
  }[];
  sevenDaysAgoAppointmentDistribution?: {
    time: string;
    percentage: number;
  }[];
  branchWaitTimes?: BranchWaitTimes[];
  opdData?: OPDChamberData;
  faultyCheckouts?: FaultyCheckout[];
}

const getWaitTimeClass = (waitTime: number): string => {
  if (waitTime > 45) {
    return "text-red-500";
  } else if (waitTime > 30) {
    return "text-yellow-500";
  } else {
    return "text-green-500";
  }
};

const getWalkInPercentageColor = (percentage: number) => {
  if (percentage < 20) {
    return "text-green-500";
  } else if (percentage < 30) {
    return "text-yellow-500";
  } else {
    return "text-red-500";
  }
};

const getCardStatus = (hospital: HospitalStats): string => {
  if (hospital.error || !hospital.appointmentData || !hospital.walkInData) {
    return "border-gray-500";
  }
  if (
    getWaitTimeClass(hospital.appointmentData.avgCheckedInWaitTime).includes(
      "red"
    )
  ) {
    return "border-red-500";
  } else if (
    getWaitTimeClass(hospital.appointmentData.avgCheckedInWaitTime).includes(
      "yellow"
    )
  ) {
    return "border-yellow-500";
  }
  return "border-green-500";
};

const ExecutiveDashboard: React.FC = () => {
  const [hospitalStats, setHospitalStats] = useState<HospitalStats[]>([]);
  const [hourlyData, setHourlyData] = useState<HourlyDataItem[]>([]);
  const navigate = useNavigate();

  const fetchHospitalData = async (hospital: Hospital) => {
    setHospitalStats((prev) =>
      prev.map((h) => (h.id === hospital.id ? { ...h, loading: true } : h))
    );
    try {
      const data = await fetchData(
        hospital.id,
        "9f011ca8-591b-4093-a903-f5ee82c7b4ff"
      );
      setHospitalStats((prev) =>
        prev.map((h) =>
          h.id === hospital.id
            ? { ...h, ...data, loading: false, error: undefined }
            : h
        )
      );
    } catch (err) {
      console.error(`Failed to fetch data for ${hospital.name}:`, err);
      setHospitalStats((prev) =>
        prev.map((h) =>
          h.id === hospital.id
            ? { ...h, error: "Failed to fetch data", loading: false }
            : h
        )
      );
    }
  };

  useEffect(() => {
    const initializeHospitalStats = () => {
      setHospitalStats(
        hospitalData.map((hospital) => ({
          id: hospital.id,
          name: hospital.name,
          loading: false,
        }))
      );
    };

    const fetchAllHospitalData = async () => {
      for (const hospital of hospitalData) {
        await fetchHospitalData(hospital);
      }
    };

    initializeHospitalStats();
    fetchAllHospitalData();

    const intervalId = setInterval(fetchAllHospitalData, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const processHourlyData = (data: HospitalStats[]) => {
    const timeSlots = [
      "9-10",
      "10-11",
      "11-12",
      "12-1",
      "1-2",
      "2-3",
      "3-4",
      "4-5",
      "5-6",
      "6-7",
      "7-8",
    ];
    const allHospitals = data.map((hospital) => hospital.name);

    const hourlyData: {
      [key: string]: {
        waitTimes: { [hospital: string]: number };
        hospitals: string[];
      };
    } = {};

    timeSlots.forEach((slot) => {
      hourlyData[slot] = {
        waitTimes: Object.fromEntries(
          allHospitals.map((hospital) => [hospital, 0])
        ),
        hospitals: allHospitals,
      };
    });

    data.forEach((hospital) => {
      if (hospital.branchWaitTimes) {
        hospital.branchWaitTimes.forEach((item) => {
          const [, timePart] = item.timestamp.split("T");
          const hour = parseInt(timePart.split(":")[0]);
          const slot = timeSlots[hour - 9];
          if (hourlyData[slot]) {
            hourlyData[slot].waitTimes[hospital.name] = item.wait_time_avg;
          }
        });
      }
    });

    const processedData: HourlyDataItem[] = timeSlots.map((slot) => ({
      time: slot,
      [slot]: "0",
      hospitals: Object.entries(hourlyData[slot].waitTimes).map(
        ([name, waitTime]) => ({
          name,
          waitTime: isNaN(waitTime) ? 0 : waitTime,
        })
      ),
    }));

    console.log("Hourly data processed:", processedData);
    setHourlyData(processedData);
  };

  useEffect(() => {
    processHourlyData(hospitalStats);
  }, [hospitalStats]);

  const totalAppointments = hospitalStats.reduce(
    (sum, hospital) => sum + (hospital.appointmentData?.all || 0),
    0
  );
  const totalWalkIns = hospitalStats.reduce(
    (sum, hospital) => sum + (hospital.walkInData?.count || 0),
    0
  );

  const highestCheckOutRate =
    hospitalStats.length > 0
      ? hospitalStats.reduce((prev, current) => {
          if (!current.appointmentData || !prev.appointmentData) return prev;
          const currentRate =
            current.appointmentData.checkedOut /
            (current.appointmentData.checkedIn +
              current.appointmentData.checkedOut);
          const prevRate =
            prev.appointmentData.checkedOut /
            (prev.appointmentData.checkedIn + prev.appointmentData.checkedOut);
          return (isNaN(currentRate) ? 0 : currentRate) >
            (isNaN(prevRate) ? 0 : prevRate)
            ? current
            : prev;
        })
      : null;

  const lowestCheckOutRate =
    hospitalStats.length > 0
      ? hospitalStats.reduce((prev, current) => {
          if (!current.appointmentData || !prev.appointmentData) return prev;
          const currentRate =
            current.appointmentData.checkedOut /
            (current.appointmentData.checkedIn +
              current.appointmentData.checkedOut);
          const prevRate =
            prev.appointmentData.checkedOut /
            (prev.appointmentData.checkedIn + prev.appointmentData.checkedOut);
          return (isNaN(currentRate) ? 0 : currentRate) <
            (isNaN(prevRate) ? 0 : prevRate)
            ? current
            : prev;
        })
      : null;

  const highestCancelRate =
    hospitalStats.length > 0
      ? hospitalStats.reduce((prev, current) => {
          if (!current.appointmentData || !prev.appointmentData) return prev;
          const currentRate =
            current.appointmentData.cancelled / current.appointmentData.all;
          const prevRate =
            prev.appointmentData.cancelled / prev.appointmentData.all;
          return (isNaN(currentRate) ? 0 : currentRate) >
            (isNaN(prevRate) ? 0 : prevRate)
            ? current
            : prev;
        })
      : null;

  const longestWaitTime =
    hospitalStats.length > 0
      ? hospitalStats.reduce((prev, current) => {
          if (!current.appointmentData || !prev.appointmentData) return prev;
          return current.appointmentData.avgCheckedInWaitTime >
            prev.appointmentData.avgCheckedInWaitTime
            ? current
            : prev;
        })
      : null;

  const shortestWaitTime =
    hospitalStats.length > 0
      ? hospitalStats.reduce((prev, current) => {
          if (!current.appointmentData || !prev.appointmentData) return prev;
          return current.appointmentData.avgCheckedInWaitTime <
            prev.appointmentData.avgCheckedInWaitTime
            ? current
            : prev;
        })
      : null;

  const highestWalkInRate =
    hospitalStats.length > 0
      ? hospitalStats.reduce((prev, current) => {
          if (!current.walkInData || !prev.walkInData) return prev;
          const currentRate = parseFloat(current.walkInData.percentage);
          const prevRate = parseFloat(prev.walkInData.percentage);
          return (isNaN(currentRate) ? 0 : currentRate) >
            (isNaN(prevRate) ? 0 : prevRate)
            ? current
            : prev;
        })
      : null;

  const averagePreBookedWaitTime = Math.round(
    hospitalStats.reduce((sum, hospital) => {
      if (hospital.appointmentData && hospital.walkInData) {
        return (
          sum +
          getPreBookedAvgCheckedInWait(
            hospital.appointmentData,
            hospital.walkInData
          ) *
            hospital.appointmentData.checkedIn
        );
      }
      return sum;
    }, 0) /
      hospitalStats.reduce(
        (sum, hospital) => sum + (hospital.appointmentData?.checkedIn || 0),
        0
      )
  );

  const averageWalkInWaitTime = Math.round(
    hospitalStats.reduce(
      (sum, hospital) =>
        sum +
        (hospital.walkInData?.avgCheckedInWaitTime || 0) *
          (hospital.walkInData?.count || 0),
      0
    ) /
      hospitalStats.reduce(
        (sum, hospital) => sum + (hospital.walkInData?.count || 0),
        0
      )
  );

  const handleGenerateDoctorAwayPDF = () => {
    generateDoctorAwayPDF(hospitalStats);
  };

  const handleGenerateFaultyCheckoutPDF = () => {
    generateFaultyCheckoutPDF(hospitalStats);
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen p-2 sm:p-4 lg:p-6">
      <Header>
        <Header.Title icon={Dashboard}>Executive Dashboard</Header.Title>
      </Header>

      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-3/4">
          <div className="bg-gray-800 rounded-lg p-3 sm:p-4 h-full">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg sm:text-xl font-semibold">
                All hospitals
              </h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 sm:gap-4">
              {hospitalStats.map((hospital) => (
                <HospitalCard
                  key={hospital.id}
                  hospital={hospital}
                  onClick={() =>
                    navigate(
                      `/opd?branch_id=${hospital.id}&service_type=9f011ca8-591b-4093-a903-f5ee82c7b4ff&refreshInterval=5`
                    )
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/4">
          <div className="bg-gray-800 rounded-lg p-3 sm:p-4 h-full overflow-y-auto">
            <div className="flex justify-between items-center mb-3 pb-2 border-b border-gray-700">
              <h2 className="text-lg sm:text-xl font-semibold">Summary</h2>
              <DownloadOptions
                onGenerateDoctorAwayPDF={handleGenerateDoctorAwayPDF}
                onGenerateFaultyCheckoutPDF={handleGenerateFaultyCheckoutPDF}
              />
            </div>
            <div className="space-y-3 sm:space-y-4">
              <div>
                <h3 className="text-base sm:text-lg text-gray-400 mb-2">
                  Appointments
                </h3>
                <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center">
                  <DateRange className="mr-2 text-xl sm:text-2xl" />
                  <div>
                    <div className="text-xs sm:text-sm">Total Appointments</div>
                    <div className="text-base sm:text-lg font-bold">
                      {totalAppointments}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center mt-2">
                  <DirectionsWalk className="mr-2 text-xl sm:text-2xl" />
                  <div>
                    <div className="text-xs sm:text-sm">Total Walk-Ins</div>
                    <div
                      className={`text-base sm:text-lg font-bold ${getWalkInPercentageColor(
                        (totalWalkIns / totalAppointments) * 100
                      )}`}
                    >
                      {totalWalkIns} (
                      {((totalWalkIns / totalAppointments) * 100 || 0).toFixed(
                        1
                      )}
                      %)
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-base sm:text-lg text-gray-400 mb-2">
                  Wait Times
                </h3>
                <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center">
                  <AccessTime className="mr-2 text-xl sm:text-2xl" />
                  <div>
                    <div className="text-xs sm:text-sm">
                      Pre-booked Avg Wait Time
                    </div>
                    <div
                      className={`text-base sm:text-lg font-bold ${getWaitTimeClass(
                        averagePreBookedWaitTime
                      )}`}
                    >
                      {isNaN(averagePreBookedWaitTime)
                        ? "0"
                        : averagePreBookedWaitTime}{" "}
                      minutes
                    </div>
                  </div>
                </div>
                <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center mt-2">
                  <AccessTime className="mr-2 text-xl sm:text-2xl" />
                  <div>
                    <div className="text-xs sm:text-sm">
                      Walk-in Avg Wait Time
                    </div>
                    <div
                      className={`text-base sm:text-lg font-bold ${getWaitTimeClass(
                        averageWalkInWaitTime
                      )}`}
                    >
                      {isNaN(averageWalkInWaitTime)
                        ? "0"
                        : averageWalkInWaitTime}{" "}
                      minutes
                    </div>
                  </div>
                </div>
                {longestWaitTime && longestWaitTime.appointmentData && (
                  <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center mt-2">
                    <AccessTime className="mr-2 text-xl sm:text-2xl text-red-500" />
                    <div>
                      <div className="text-xs sm:text-sm">
                        Maximum Avg Wait Time
                      </div>
                      <div className="text-base sm:text-lg font-bold">
                        {longestWaitTime.name} (
                        {isNaN(
                          longestWaitTime.appointmentData.avgCheckedInWaitTime
                        )
                          ? "0"
                          : longestWaitTime.appointmentData
                              .avgCheckedInWaitTime}
                        m)
                      </div>
                    </div>
                  </div>
                )}
                {shortestWaitTime && shortestWaitTime.appointmentData && (
                  <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center mt-2">
                    <AccessTime className="mr-2 text-xl sm:text-2xl text-green-500" />
                    <div>
                      <div className="text-xs sm:text-sm">
                        Minimum Avg Wait Time
                      </div>
                      <div className="text-base sm:text-lg font-bold">
                        {shortestWaitTime.name} (
                        {isNaN(shortestWaitTime.appointmentData.avgWaitTime)
                          ? "0"
                          : shortestWaitTime.appointmentData.avgWaitTime}
                        m)
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <h3 className="text-base sm:text-lg text-gray-400 mb-2">
                  Rates
                </h3>
                {highestCancelRate && highestCancelRate.appointmentData && (
                  <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center">
                    <Cancel className="mr-2 text-xl sm:text-2xl text-red-500" />
                    <div>
                      <div className="text-xs sm:text-sm">
                        Maximum Cancellation Rate
                      </div>
                      <div className="text-base sm:text-lg font-bold">
                        {highestCancelRate.name} (
                        {(
                          (highestCancelRate.appointmentData.cancelled /
                            highestCancelRate.appointmentData.all) *
                            100 || 0
                        ).toFixed(1)}
                        % )
                      </div>
                    </div>
                  </div>
                )}
                {highestWalkInRate && highestWalkInRate.walkInData && (
                  <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center mt-2">
                    <DirectionsWalk className="mr-2 text-xl sm:text-2xl text-red-500" />
                    <div>
                      <div className="text-xs sm:text-sm">
                        Maximum Walk-In %
                      </div>
                      <div className="text-base sm:text-lg font-bold">
                        {highestWalkInRate.name} (
                        {highestWalkInRate.walkInData.percentage || "0%"})
                      </div>
                    </div>
                  </div>
                )}
                {highestCheckOutRate && highestCheckOutRate.appointmentData && (
                  <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center mt-2">
                    <ExitToApp className="mr-2 text-xl sm:text-2xl text-green-500" />
                    <div>
                      <div className="text-xs sm:text-sm">
                        Maximum Check-out Rate
                      </div>
                      <div className="text-base sm:text-lg font-bold">
                        {highestCheckOutRate.name} (
                        {(
                          (highestCheckOutRate.appointmentData.checkedOut /
                            (highestCheckOutRate.appointmentData.checkedIn +
                              highestCheckOutRate.appointmentData.checkedOut)) *
                            100 || 0
                        ).toFixed(1)}
                        % )
                      </div>
                    </div>
                  </div>
                )}
                {lowestCheckOutRate && lowestCheckOutRate.appointmentData && (
                  <div className="bg-gray-700 rounded-lg p-2 sm:p-3 flex items-center mt-2">
                    <ExitToApp className="mr-2 text-xl sm:text-2xl text-red-500" />
                    <div>
                      <div className="text-xs sm:text-sm">
                        Minimum Check-out Rate
                      </div>
                      <div className="text-base sm:text-lg font-bold">
                        {lowestCheckOutRate.name} (
                        {(
                          (lowestCheckOutRate.appointmentData.checkedOut /
                            (lowestCheckOutRate.appointmentData.checkedIn +
                              lowestCheckOutRate.appointmentData.checkedOut)) *
                            100 || 0
                        ).toFixed(1)}
                        % )
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 bg-gray-800 rounded-lg p-3 sm:p-4">
        <h2 className="text-lg sm:text-xl font-semibold mb-3">Hourly View</h2>
        <HourlyOPDViewPopup hourlyData={hourlyData} />
      </div>
    </div>
  );
};

export default ExecutiveDashboard;

interface HospitalCardProps {
  hospital: HospitalStats;
  onClick: () => void;
}
const HospitalCard: React.FC<HospitalCardProps> = ({ hospital, onClick }) => (
  <div
    className={`bg-gray-700 rounded-lg p-3 cursor-pointer transition duration-300 hover:bg-gray-600 relative overflow-hidden border-2 ${getCardStatus(
      hospital
    )}`}
    onClick={onClick}
  >
    {hospital.loading && (
      <div className="absolute inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
        <div className="animate-spin rounded-full h-6 w-6 sm:h-8 sm:w-8 border-b-2 border-white"></div>
      </div>
    )}
    <h3 className="text-base sm:text-lg font-semibold mb-2">
      {hospital.name.replace("Max", "")}
    </h3>
    {hospital.error ? (
      <div className="text-red-500 text-sm">Failed to load data</div>
    ) : hospital.appointmentData && hospital.walkInData ? (
      <>
        <div className="bg-gray-800 rounded p-1 mb-1">
          <div className="flex justify-between text-xs sm:text-sm mb-1">
            <span>Appointments:</span>
            <span>{hospital.appointmentData.all}</span>
          </div>
          <div className="flex justify-between text-xs sm:text-sm">
            <span>Check-out:</span>
            <span>
              {(
                (hospital.appointmentData.checkedOut /
                  (hospital.appointmentData.checkedIn +
                    hospital.appointmentData.checkedOut)) *
                  100 || 0
              ).toFixed(1)}
              %
            </span>
          </div>
        </div>
        <div className="bg-gray-800 rounded p-1 mb-1">
          <div className="text-xs sm:text-sm font-semibold mb-1">
            Pre-booked
          </div>
          <div className="flex justify-between text-xs sm:text-sm">
            <span>Wait Time:</span>
            <span
              className={getWaitTimeClass(
                Math.round(
                  getPreBookedAvgCheckedInWait(
                    hospital.appointmentData,
                    hospital.walkInData
                  )
                ) || 0
              )}
            >
              {Math.round(
                getPreBookedAvgCheckedInWait(
                  hospital.appointmentData,
                  hospital.walkInData
                )
              ) || 0}
              m
            </span>
          </div>
        </div>
        <div className="bg-gray-800 rounded p-1">
          <div className="text-xs sm:text-sm font-semibold mb-1">Walk-In</div>
          <div className="flex justify-between text-xs sm:text-sm mb-1">
            <span>Percentage:</span>
            <span
              className={getWalkInPercentageColor(
                parseFloat(hospital.walkInData.percentage) || 0
              )}
            >
              {hospital.walkInData.percentage || "0%"}
            </span>
          </div>
          <div className="flex justify-between text-xs sm:text-sm">
            <span>Wait Time:</span>
            <span
              className={getWaitTimeClass(
                hospital.walkInData.avgCheckedInWaitTime || 0
              )}
            >
              {hospital.walkInData.avgCheckedInWaitTime || 0}m
            </span>
          </div>
        </div>
      </>
    ) : null}
  </div>
);

interface DownloadOptionsProps {
  onGenerateDoctorAwayPDF: () => void;
  onGenerateFaultyCheckoutPDF: () => void;
}

const DownloadOptions: React.FC<DownloadOptionsProps> = ({
  onGenerateDoctorAwayPDF,
  onGenerateFaultyCheckoutPDF,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDoctorAwayPDF = () => {
    onGenerateDoctorAwayPDF();
    handleClose();
  };

  const handleFaultyCheckoutPDF = () => {
    onGenerateFaultyCheckoutPDF();
    handleClose();
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        startIcon={<PictureAsPdf />}
        endIcon={<ArrowDropDown />}
        className="bg-green-500 text-white hover:bg-green-600"
      >
        Download Report
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDoctorAwayPDF}>Doctor Away Summary</MenuItem>
        <MenuItem onClick={handleFaultyCheckoutPDF}>
          Anomalous Checkout Summary
        </MenuItem>
      </Menu>
    </div>
  );
};
