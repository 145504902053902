import { Cancel } from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { IUpdatedPatientActionData } from ".";
import { Button } from "../components/button";
import {
  severityColorPalette,
  timestampToDaytime,
  titleCase,
} from "../helpers";
import CloseConfirmationModal from "./closeConfirmationModal";
import {
  IEscalationData,
  TEscalationAction,
  TPatientAction,
} from "./fetchEscalations";

export type TActionMode = "add" | "view" | "replace";

interface IActionModalProps {
  open: boolean;
  mode: TActionMode;
  doctorName: string;
  delayedTime: number | null;
  avgWaitTime: number;
  doctorStatus: TEscalationAction;
  escalatedAppointmentData: IEscalationData | null;
  onClose: () => void;
  handleSubmitButton: (d: IUpdatedPatientActionData[]) => void;
}

const ActionsBasedOnDoctorStatus: Record<
  TEscalationAction,
  {
    heading: string;
    POSITIVE: string;
    NEGATIVE: string;
  }
> = {
  DOCTOR_DELAY: {
    heading: "Action Taken",
    POSITIVE: "Informed",
    NEGATIVE: "Not Required",
  },
  DOCTOR_LEAVE: {
    heading: "Appointment Updated",
    POSITIVE: "Yes",
    NEGATIVE: "Not yet",
  },
  MANUAL_PRESCRIPTION: {
    heading: "Consultation Status",
    POSITIVE: "Done",
    NEGATIVE: "Pending",
  },
  DOCTOR_NOT_REACHABLE: {
    heading: "Action Taken",
    POSITIVE: "Informed",
    NEGATIVE: "Not Required",
  },
};

const WARNING_TEXT = {
  DOCTOR_DELAY:
    "Please inform patients as per doctor's instructions to avoid further escalation.",
  DOCTOR_LEAVE:
    "Please mark doctor on leave and ensure that all the appointments are cancelled or rescheduled for the day within next 30 mins to prevent further escalation.",
  MANUAL_PRESCRIPTION:
    "Please manually check-out the patients for whom consultation has been done to avoid further escalation.",
  DOCTOR_NOT_REACHABLE: "",
};

const ActionModal = ({
  open,
  mode = "add",
  onClose,
  doctorName,
  delayedTime,
  avgWaitTime,
  escalatedAppointmentData: data,
  doctorStatus,
  handleSubmitButton,
}: IActionModalProps) => {
  const appointmentData = useMemo(() => {
    if (mode === "replace") {
      return {
        ...data,
        escalations: data?.escalations.map((e) => ({
          ...e,
          patient_action_taken: null,
        })),
      };
    }
    return data;
  }, [data, mode]);

  const [updatedPatientActionData, setUpdatedPatientActionData] = useState<
    IUpdatedPatientActionData[]
  >([]);

  const [openWarningModal, setOpenWarningModal] = useState(false);

  const checkedInPatients = useMemo(() => {
    if (appointmentData) {
      return appointmentData?.escalations?.filter((d) => {
        return d.patient_checkedin_time !== null;
      });
    }
    return [];
  }, [appointmentData]);

  const yetToArrivePatients = useMemo(() => {
    if (appointmentData) {
      return appointmentData?.escalations?.filter((d) => {
        return d.patient_checkedin_time === null;
      });
    }
    return [];
  }, [appointmentData]);

  // we need to get appointment id from here for the latest value
  const handlePatientActionUpdate = useCallback(
    (action: TPatientAction, appointmentId: string) => {
      setUpdatedPatientActionData((prev) => {
        if (prev.find((d) => d.appointment_id === appointmentId)) {
          // update existing appointment object with updated action
          return prev.map((d) => {
            if (d.appointment_id === appointmentId) {
              return {
                ...d,
                patient_action_taken: action,
              };
            }
            return d;
          });
        }

        return [
          ...prev,
          {
            appointment_id: appointmentId ?? "",
            patient_action_taken: action,
          },
        ] as IUpdatedPatientActionData[];
      });
    },
    []
  );

  const handleClose = useCallback(() => {
    onClose();
    setUpdatedPatientActionData([]);
  }, [onClose]);

  const handleOpenCloseWarningModal = useCallback(() => {
    setOpenWarningModal(true);
  }, []);

  const handleSubmit = useCallback(() => {
    const dataToBeSubmitted: IUpdatedPatientActionData[] =
      mode === "replace"
        ? appointmentData?.escalations?.map((e) => {
            return {
              appointment_id: String(e.appointment_id),
              patient_action_taken:
                updatedPatientActionData.find(
                  (d) => d.appointment_id === e.appointment_id
                )?.patient_action_taken ?? null,
            };
          }) ?? []
        : updatedPatientActionData;

    handleSubmitButton(dataToBeSubmitted);
    handleClose();
  }, [
    appointmentData?.escalations,
    handleClose,
    handleSubmitButton,
    mode,
    updatedPatientActionData,
  ]);

  return (
    <Dialog
      open={open}
      onClose={
        mode !== "view" && updatedPatientActionData.length > 0
          ? handleOpenCloseWarningModal
          : handleClose
      }
      maxWidth="lg"
      className="rounded-lg"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "black",
          borderRadius: "8px",
          color: "white",
          boxShadow: "0 0 10px rgba(255, 255, 255, 0.1)",
          filter: "none", // Ensure the popup is not blurred
          maxHeight: "600px",
        },
      }}
    >
      <CloseConfirmationModal
        open={openWarningModal}
        handleCancel={() => setOpenWarningModal(false)}
        handleCloseWithoutSaving={() => {
          handleClose();
          setOpenWarningModal(false);
        }}
        handleSaveAAndClose={() => {
          handleSubmit();
          setOpenWarningModal(false);
        }}
      />

      <DialogTitle className="flex justify-between items-center  bg-[#111827] text-white">
        <div className="flex gap-20">
          <span className="text-xl font-semibold">{titleCase(doctorName)}</span>

          <div className="flex flex-col justify-center items-center text-xs">
            <p>Avg. Wait Time</p>
            <p className="font-bold text-sm">{avgWaitTime}</p>
          </div>

          {doctorStatus === "DOCTOR_DELAY" && delayedTime !== null && (
            <div className="flex flex-col justify-center items-center text-xs">
              <p> Delayed Time</p>
              <p className="font-bold text-sm">{delayedTime + " mins"}</p>
            </div>
          )}
        </div>

        <div>
          <IconButton
            onClick={
              mode !== "view" && updatedPatientActionData.length > 0
                ? handleOpenCloseWarningModal
                : handleClose
            }
            size="small"
            style={{ color: "white" }}
          >
            <Cancel />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className="flex items-center justify-center bg-[#12141e] flex-col !px-5 !py-4 gap-3 !overflow-hidden ">
        {mode !== "view" && doctorStatus !== "DOCTOR_NOT_REACHABLE" && (
          <div className="flex justify-center items-center">
            <WarningIcon color="warning" />
            <span className="inline-block ml-2 text-sm">
              {WARNING_TEXT[doctorStatus]}
            </span>
          </div>
        )}

        <div className="h-full overflow-y-scroll w-full">
          <table className="w-full min-w-full divide-y divide-gray-500 rounded-lg">
            <thead className="bg-gray-800 rounded-t-lg ">
              <tr className="rounded-t-lg">
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-bold text-gray-300 uppercase tracking-wider rounded-tl-lg"
                >
                  Patient List
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-sm  text-center font-bold text-gray-300 uppercase tracking-wider"
                >
                  Level
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center text-sm font-bold text-gray-300 uppercase tracking-wider"
                >
                  Wait Time(in mins)
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center text-sm font-bold text-gray-300 uppercase tracking-wider"
                >
                  Appointment Time
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center text-sm  text-gray-300 uppercase tracking-wider font-bold"
                >
                  Contact
                </th>

                {mode !== "view" && (
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm  text-gray-300 uppercase tracking-wider font-bold rounded-tr-lg"
                  >
                    {ActionsBasedOnDoctorStatus[doctorStatus].heading}
                  </th>
                )}
              </tr>
            </thead>

            <tbody className="bg-gray-700 divide-y divide-gray-500 rounded-b-lg overflow-y-scroll">
              {checkedInPatients?.map((data, i) => {
                const actionData =
                  updatedPatientActionData.find(
                    (d) => data.appointment_id === d.appointment_id
                  )?.patient_action_taken ?? data.patient_action_taken;

                return (
                  <tr key={i} className="bg-gray-800 hover:bg-gray-700">
                    <td
                      className="px-6 py-1.5 whitespace-nowrap text-sm"
                      colSpan={1}
                    >
                      {titleCase(data.patient_name)}
                    </td>

                    <td
                      className={`px-6 py-1.5 whitespace-nowrap text-sm `}
                      colSpan={1}
                    >
                      <div
                        className={`bg-opacity-50 border-2 text-center rounded-full p-1 font-semibold shadow-md ${
                          severityColorPalette[data.escalation_type]
                        }`}
                      >
                        {data.escalation_type === "NO_ESCALATION"
                          ? "NE"
                          : data.escalation_type}
                      </div>
                    </td>

                    <td
                      className="px-6 py-1.5 whitespace-nowrap text-center text-sm"
                      colSpan={1}
                    >
                      {data.wait_time}
                    </td>

                    <td
                      className="px-6 py-1.5 whitespace-nowrap text-center text-sm"
                      colSpan={1}
                    >
                      {timestampToDaytime(data.start_time)}
                    </td>

                    <td
                      className="px-6 py-1.5 whitespace-nowrap text-center text-sm"
                      colSpan={1}
                    >
                      {data.mobile}
                    </td>

                    {mode !== "view" && (
                      <td
                        className="px-6 py-1.5 flex gap-2 whitespace-nowrap text-sm"
                        colSpan={2}
                      >
                        <Button
                          buttonType={
                            actionData === "POSITIVE" ? "green" : "tag"
                          }
                          onClick={() =>
                            handlePatientActionUpdate(
                              "POSITIVE",
                              data?.appointment_id
                            )
                          }
                        >
                          {ActionsBasedOnDoctorStatus[doctorStatus].POSITIVE}
                        </Button>

                        <Button
                          buttonType={
                            actionData === "NEGATIVE" ? "selectedTag" : "tag"
                          }
                          onClick={() =>
                            handlePatientActionUpdate(
                              "NEGATIVE",
                              data?.appointment_id
                            )
                          }
                          disabled={data.patient_action_taken === "POSITIVE"}
                        >
                          {ActionsBasedOnDoctorStatus[doctorStatus].NEGATIVE}
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}

              {yetToArrivePatients?.length !== 0 && (
                <tr className="bg-gray-700 hover:bg-gray-700 text-center">
                  <td
                    colSpan={6}
                    className="px-6 py-1.5 whitespace-nowrap text-sm font-semibold text-slate-300"
                  >
                    Yet to arrive Patients
                  </td>
                </tr>
              )}

              {yetToArrivePatients?.map((data, i) => {
                const actionData =
                  updatedPatientActionData.find(
                    (d) => data.appointment_id === d.appointment_id
                  )?.patient_action_taken ?? data.patient_action_taken;

                return (
                  <tr key={i} className="bg-gray-800 hover:bg-gray-700">
                    <td className="px-6 py-1.5 whitespace-nowrap text-sm">
                      {titleCase(data.patient_name)}
                    </td>

                    <td
                      className={`px-6 py-1.5 whitespace-nowrap text-sm `}
                      colSpan={1}
                    >
                      <div
                        className={` text-center rounded-full p-1 font-semibold shadow-md bg-opacity-50 border-2 ${
                          severityColorPalette[data.escalation_type]
                        }`}
                      >
                        {data.escalation_type === "NO_ESCALATION"
                          ? "NE"
                          : data.escalation_type}
                      </div>
                    </td>

                    <td className="px-6 py-1.5 whitespace-nowrap text-center text-sm">
                      Yet to Arrive
                    </td>

                    <td className="px-6 py-1.5 whitespace-nowrap text-center text-sm">
                      {timestampToDaytime(data.start_time)}
                    </td>

                    <td className="px-6 py-1.5 whitespace-nowrap text-center text-sm">
                      {data.mobile}
                    </td>

                    {mode !== "view" && (
                      <td
                        className="px-6 py-1.5 flex gap-2 whitespace-nowrap text-sm"
                        colSpan={2}
                      >
                        <Button
                          buttonType={
                            actionData === "POSITIVE" ? "green" : "tag"
                          }
                          onClick={() =>
                            handlePatientActionUpdate(
                              "POSITIVE",
                              data?.appointment_id
                            )
                          }
                        >
                          {ActionsBasedOnDoctorStatus[doctorStatus].POSITIVE}
                        </Button>

                        <Button
                          buttonType={
                            actionData === "NEGATIVE" ? "selectedTag" : "tag"
                          }
                          onClick={() =>
                            handlePatientActionUpdate(
                              "NEGATIVE",
                              data?.appointment_id
                            )
                          }
                        >
                          {ActionsBasedOnDoctorStatus[doctorStatus].NEGATIVE}
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {mode !== "view" &&
          !appointmentData?.escalations?.every(
            (esc) => esc.patient_action_taken === "POSITIVE"
          ) && (
            <Button
              onClick={handleSubmit}
              buttonType="blue"
              className="!px-5 !py-2"
              disabled={updatedPatientActionData.length < 1}
            >
              Submit
            </Button>
          )}
      </DialogContent>
    </Dialog>
  );
};

export { ActionModal };
