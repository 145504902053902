import "./App.css";

function App() {
  return (
    // default page with general poetry about health care importance and command center importance
    <div className="App">
      <header className="App-header">
        <h1>Health Care Command Center</h1>
        <p>Welcome to the Health Care Command Center</p>
        <p>
          This is a place where you can monitor and manage the health care
          system
        </p>
        <a className="App-link" href="/executive" rel="noopener noreferrer">
          Executive OPD Dashboard
        </a>
      </header>
    </div>
  );
}

export default App;
