import { AppointmentData, WalkInData } from "./models";

export const getPreBookedAvgCheckedInWait = (appointmentData: AppointmentData, walkInData: WalkInData) => {
    const preBookedCheckedInCount = appointmentData.waitCount - walkInData.waitCount;
    if (preBookedCheckedInCount > 0) {
        return (
            (appointmentData.avgCheckedInWaitTime * appointmentData.waitCount -
                walkInData.avgCheckedInWaitTime *
                walkInData.waitCount) /
            preBookedCheckedInCount
        );
    }
    return 0;
};

export const getPreBookedAvgWait = (appointmentData: AppointmentData, walkInData: WalkInData) => {
    const totalAppointments = appointmentData.all;
    const preBookedCount = totalAppointments - walkInData.count;
    if (preBookedCount > 0) {
        return (
            (appointmentData.avgWaitTime * totalAppointments -
                walkInData.avgWaitTime * walkInData.count) /
            preBookedCount
        );
    }
    return 0;
};