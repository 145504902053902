// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const isAuthenticated = localStorage.getItem('token') !== null; // Replace with your auth check
    console.log('isAuthenticated:', isAuthenticated);
    console.log('token:', localStorage.getItem('token'));
    const location = useLocation();

    if (!isAuthenticated) {
        // Redirect to the login page with the return url
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;