import { TEscalationLevel } from "../escalation/fetchEscalations";

// title casing the name
export function titleCase(name: string) {
  return name.replace(/\b\w/g, (char) => char.toUpperCase());
}

// convert timestamp to readable date
export function convertTimestampToReadableString(timestamp: number) {
  const date = new Date(timestamp * 1000);

  // Using toUTCString() for a standard format
  // const readableDateUTC = date.toUTCString();
  // Sun, 13 Oct 2024 12:30:00 GMT

  // Using toLocaleString() for a more customizable format
  const readableDateLocal = date.toLocaleString("en-US", {
    timeZone: "UTC",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  // October 13, 2024, 12:30:00 PM

  return readableDateLocal;
}

export function timestampToDaytime(timestamp: number) {
  const date = new Date(timestamp * 1000);

  return date.toLocaleString("en-US", {
    timeZone: "IST",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export const severityColorPalette: Record<TEscalationLevel, string> = {
  E1: " bg-[#fff187] border-[#efe7a3]",
  E2: " bg-[#f7cb52] border-[#facc33] ",
  E3: " border-[#de9144]  bg-[#e69b50] ",
  E4: " border-[#c22c31] bg-[#c52f34] ",
  NO_ESCALATION: " border border-white ",
  SILENCED: " border border-white",
};
