import { useEffect, useState } from "react";

const useEscalationViewEnabled = () => {
  const [isEnabled, setIsEnabled] = useState(() => {
    const storedValue = localStorage.getItem("escalation_view_enabled");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    localStorage.setItem("escalation_view_enabled", JSON.stringify(isEnabled));
  }, [isEnabled]);

  const setEscalationViewEnabled = (value: boolean) => {
    setIsEnabled(value);
  };

  return [isEnabled, setEscalationViewEnabled];
};

export default useEscalationViewEnabled;
