import { Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

interface ICloseConfirmationModalProps {
  open: boolean;
  handleCancel: () => void;
  handleCloseWithoutSaving: () => void;
  handleSaveAAndClose: () => void;
}

const CloseConfirmationModal = ({
  open,
  handleCancel,
  handleCloseWithoutSaving,
  handleSaveAAndClose,
}: ICloseConfirmationModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      className="rounded-lg"
      PaperProps={{
        style: {
          backgroundColor: "black",
          borderRadius: "8px",
          color: "white",
          boxShadow: "2px 2px 10px rgba(255, 255, 255, 0.1)",
          filter: "none", // Ensure the popup is not blurred
          maxHeight: "600px",
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center bg-[#0B192C] text-white">
        Warning: Unsaved Changes
        <div>
          <IconButton
            onClick={handleCancel}
            size="small"
            style={{ color: "white" }}
          >
            <Cancel />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className="flex items-center justify-center flex-col w-full !px-5 !py-4 gap-3 ">
        By closing this, all progress will be lost. Do you want to save the
        actions taken before closing?
        <div className="flex flex-row w-full justify-between mt-4">
          <Button
            onClick={handleCancel}
            variant="text"
            color="info"
            className="!px-5 !py-2"
          >
            Cancel
          </Button>

          <div className="flex gap-2 ">
            <Button
              onClick={handleCloseWithoutSaving}
              className="!px-5 !py-2"
              variant="text"
              color="info"
            >
              Close Without Saving
            </Button>

            <Button
              onClick={handleSaveAAndClose}
              className="!px-5 !py-2"
              variant="contained"
              color="info"
            >
              Save and Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CloseConfirmationModal;
