import React from 'react';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { HospitalStats } from './ExecutiveDashboard';
import { FaultyCheckout } from '../opd/models';

const styles = StyleSheet.create({
    page: { flexDirection: 'column', backgroundColor: '#ffffff', padding: 30 },
    section: { marginBottom: 10 },
    title: { fontSize: 18, fontWeight: 'bold', marginBottom: 10 },
    subtitle: { fontSize: 8, marginBottom: 10 },
    table: { display: 'flex', width: 'auto', borderStyle: 'solid', borderWidth: 1, borderColor: '#bfbfbf' },
    tableRow: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#bfbfbf' },
    tableColHeader: { width: '14.28%', borderStyle: 'solid', borderRightWidth: 1, borderRightColor: '#bfbfbf', backgroundColor: '#f0f0f0', padding: 5 },
    tableCol: { width: '14.28%', borderStyle: 'solid', borderRightWidth: 1, borderRightColor: '#bfbfbf', padding: 5 },
    tableCellHeader: { margin: 'auto', marginTop: 5, fontSize: 10, fontWeight: 'bold' },
    tableCell: { margin: 'auto', marginTop: 5, fontSize: 8 },
    withoutEPHeader: { width: '28.56%', borderStyle: 'solid', borderRightWidth: 1, borderRightColor: '#bfbfbf', backgroundColor: '#f0f0f0', padding: 5 },
    withEPHeader: { width: '28.56%', borderStyle: 'solid', borderRightWidth: 1, borderRightColor: '#bfbfbf', backgroundColor: '#f0f0f0', padding: 5 },
});

const getMins = (time: string) => {
    const [hours, mins, secs] = time.split(':');
    return parseInt(hours) * 60 + parseInt(mins) + parseInt(secs) / 60;
}

const FaultyCheckoutPDF = ({ hospitals }: { hospitals: HospitalStats[] }) => (
    <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.title}>Anomalous Checkout(s) Report</Text>
                <Text style={styles.subtitle}>{`Generated on: ${new Date().toLocaleString()}`}</Text>
            </View>
            <View style={styles.table}>
                {/* Main Header Row */}
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Hospital Name</Text></View>
                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Total Checkout</Text></View>
                    <View style={styles.withoutEPHeader}><Text style={styles.tableCellHeader}>Checkout without EP</Text></View>
                    <View style={styles.withEPHeader}><Text style={styles.tableCellHeader}>Checkout with EP</Text></View>
                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Total</Text></View>
                </View>
                {/* Subheader Row */}
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}></View>
                    <View style={styles.tableColHeader}></View>
                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Count</Text></View>
                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Consult Duration &lt; 2 min</Text></View>
                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Count</Text></View>
                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Consult Duration &lt; 2 min</Text></View>
                    <View style={styles.tableColHeader}></View>
                </View>
                {/* Data Rows */}
                {hospitals.map((hospital, index) => {
                    const totalCheckout = hospital.appointmentData?.checkedOut;
                    const checkoutWithoutEP = hospital.faultyCheckouts?.filter((fc: FaultyCheckout) => !fc.prescription_ready_time).length;
                    const checkoutWithEP = hospital.faultyCheckouts?.filter((fc: FaultyCheckout) => fc.prescription_ready_time).length;
                    const consultDurationLessThan2MinutesWithoutEP = hospital.faultyCheckouts?.filter((fc: FaultyCheckout) => getMins(fc.consultation_duration_time) < 2 && !fc.prescription_ready_time).length;
                    const consultDurationLessThan2MinutesWithEP = hospital.faultyCheckouts?.filter((fc: FaultyCheckout) => getMins(fc.consultation_duration_time) < 2 && fc.prescription_ready_time).length;
                    const total = hospital.faultyCheckouts?.length;

                    return (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{hospital.name.replace('Max', '')}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{totalCheckout}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{checkoutWithoutEP}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{consultDurationLessThan2MinutesWithoutEP}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{checkoutWithEP}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{consultDurationLessThan2MinutesWithEP}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{total}</Text></View>
                        </View>
                    );
                })}
            </View>
        </Page>
    </Document>
);

export const generateFaultyCheckoutPDF = async (hospitals: HospitalStats[]) => {
    const blob = await pdf(<FaultyCheckoutPDF hospitals={hospitals} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'faulty_checkout_summary.pdf';
    link.click();
    URL.revokeObjectURL(url);
};

export default FaultyCheckoutPDF;