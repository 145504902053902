import {
  CheckCircle as CheckCircleIcon,
  Event as EventIcon,
  TableViewOutlined as GridIcon,
  PrintTwoTone as PrintIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { Patient, WaitTimes } from "./models";

type FilterType = "total" | "45" | "30" | "15" | null;

interface PatientsWaitingProps {
  patients: Patient[];
  waitTimes: WaitTimes;
}

export const getWaitTimeClass = (waitTime: number): string => {
  if (waitTime > 45) return "text-red-500";
  if (waitTime > 30) return "text-yellow-500";
  return "text-green-500";
};

const convertToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

const WaitTimeTile: React.FC<{
  label: string;
  count: number;
  filterValue: FilterType;
  isActive: boolean;
  onClick: (filter: FilterType) => void;
}> = ({ label, count, filterValue, isActive, onClick }) => (
  <button
    className={`flex flex-col justify-center items-center p-2 rounded-md transition-all duration-300 ${
      isActive ? "ring-2 ring-white scale-105" : "hover:bg-opacity-80"
    } ${
      filterValue === "total"
        ? "bg-white text-gray-900"
        : filterValue === "45"
        ? "bg-red-500 text-white"
        : filterValue === "30"
        ? "bg-yellow-500 text-gray-900"
        : "bg-green-500 text-gray-900"
    }`}
    onClick={() => onClick(filterValue)}
  >
    <span className="text-s font-medium">{label}</span>
    <span className="text-2xl font-bold">{count}</span>
  </button>
);

const PatientsWaiting: React.FC<PatientsWaitingProps> = ({
  patients,
  waitTimes,
}) => {
  const [currentFilter, setCurrentFilter] = useState<FilterType>(null);

  const sortedPatients = [...patients].sort(
    (a, b) => convertToMinutes(b.waitTime) - convertToMinutes(a.waitTime)
  );

  const handleFilterChange = (filter: FilterType) => {
    setCurrentFilter(currentFilter === filter ? null : filter);
  };

  const filterPatients = (patients: Patient[]): Patient[] => {
    if (!currentFilter || currentFilter === "total") return patients;
    const filterMinutes = parseInt(currentFilter);
    const lowerBound = filterMinutes;
    const upperBound = filterMinutes === 45 ? Infinity : filterMinutes + 15;
    return patients.filter((patient) => {
      const waitTimeMinutes = convertToMinutes(patient.waitTime);
      return waitTimeMinutes > lowerBound && waitTimeMinutes <= upperBound;
    });
  };

  const handlePrintPatients = () => {
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      const content = `
                <html>
                    <head>
                        <title>Patients Waiting</title>
                        <style>
                            body { font-family: Arial, sans-serif; }
                            table { width: 100%; border-collapse: collapse; }
                            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                            th { background-color: #f2f2f2; }
                            .wait-time-45 { color: red; }
                            .wait-time-30 { color: orange; }
                            .wait-time-15 { color: green; }
                        </style>
                    </head>
                    <body>
                        <h1>Patients Waiting</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th>Patient</th>
                                    <th>Phone No.</th>
                                    <th>Doctor</th>
                                    <th>Wait Time</th>
                                    <th>Appt Time</th>
                                    <th>Checked In</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${filterPatients(sortedPatients)
                                  .map(
                                    (patient) => `
                                    <tr>
                                        <td>${patient.name}</td>
                                        <td>${patient.phone}</td>
                                        <td>${patient.doctor}${
                                      patient.doctorCode
                                        ? ` <br>#${patient.doctorCode}`
                                        : ""
                                    }</td>
                                        <td class="${getWaitTimeClass(
                                          convertToMinutes(patient.waitTime)
                                        )}">${convertToMinutes(
                                      patient.waitTime
                                    )} min</td>
                                        <td>${patient.appointmentTime}</td>
                                        <td>${patient.checkedInTime}</td>
                                    </tr>
                                `
                                  )
                                  .join("")}
                            </tbody>
                        </table>
                    </body>
                </html>
            `;
      printWindow.document.write(content);
      printWindow.document.close();
      printWindow.print();
    }
  };

  const handleCSVExport = () => {
    const csvContent = [
      "Patient,Phone No.,Doctor,Wait Time,Appt Time,Checked In",
      ...filterPatients(sortedPatients).map((patient) =>
        [
          patient.name,
          patient.phone,
          `${patient.doctor}${
            patient.doctorCode ? ` #${patient.doctorCode}` : ""
          }`,
          `${convertToMinutes(patient.waitTime)} min`,
          patient.appointmentTime,
          patient.checkedInTime,
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "patients_waiting.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-900 text-white p-2 rounded-lg">
      <div className="bg-gray-800 p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg sm:text-xl font-semibold">Patients Waiting</h2>
          <div className="flex">
            <IconButton
              onClick={handlePrintPatients}
              className="text-white mr-2"
            >
              <PrintIcon color="primary" />
            </IconButton>
            <IconButton onClick={handleCSVExport} className="text-white">
              <GridIcon color="primary" />
            </IconButton>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
          <WaitTimeTile
            label="Total"
            count={waitTimes.total}
            filterValue="total"
            isActive={currentFilter === "total"}
            onClick={handleFilterChange}
          />
          <WaitTimeTile
            label=">45 min"
            count={waitTimes.greaterThan45}
            filterValue="45"
            isActive={currentFilter === "45"}
            onClick={handleFilterChange}
          />
          <WaitTimeTile
            label="30-45 min"
            count={waitTimes.greaterThan30}
            filterValue="30"
            isActive={currentFilter === "30"}
            onClick={handleFilterChange}
          />
          <WaitTimeTile
            label="15-30 min"
            count={waitTimes.greaterThan15}
            filterValue="15"
            isActive={currentFilter === "15"}
            onClick={handleFilterChange}
          />
        </div>
      </div>
      <div className="overflow-y-auto flex-grow">
        <table className="w-full border-collapse">
          <thead className="bg-gray-800 sticky top-0">
            <tr>
              <th className="p-2 text-left">Patient</th>
              <th className="p-2 text-left">Phone No.</th>
              <th className="p-2 text-left">Doctor</th>
              <th className="p-2 text-left">Wait Time</th>
              <th className="p-2 text-left">Appt Time</th>
              <th className="p-2 text-left">Checked In</th>
            </tr>
          </thead>
          <tbody>
            {filterPatients(sortedPatients).map((patient, index) => (
              <tr
                key={patient.id}
                className={`border-b border-gray-700 transition-all duration-300 animate-fade-in ${
                  index % 2 === 1 ? "bg-gray-800" : "bg-gray-900"
                }`}
              >
                <td className="p-2">
                  <div className="font-bold">{patient.name}</div>
                </td>
                <td className="p-2">
                  <div className="font-bold">{patient.phone}</div>
                </td>
                <td className="p-2">
                  <div className="font-bold">{patient.doctor}</div>
                  {patient.doctorCode && (
                    <div className="text-xs text-gray-400">
                      #{patient.doctorCode}
                    </div>
                  )}
                </td>
                <td className="p-2">
                  <span
                    className={`font-bold ${getWaitTimeClass(
                      convertToMinutes(patient.waitTime)
                    )}`}
                  >
                    {convertToMinutes(patient.waitTime)} min
                  </span>
                </td>
                <td className="p-2">
                  <div className="flex items-center font-bold">
                    <EventIcon className="w-4 h-4 mr-1" />
                    <span>{patient.appointmentTime}</span>
                  </div>
                </td>
                <td className="p-2">
                  <div className="flex items-center font-bold mt-1">
                    <CheckCircleIcon className="w-4 h-4 mr-1" />
                    <span>{patient.checkedInTime}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PatientsWaiting;
