import {
  ArrowBack,
  CheckCircleOutlineOutlined,
  ExpandLess,
  ExpandMore,
  TableViewOutlined as GridIcon,
  LocalHospitalRounded as HospitalIcon,
  PersonAddAlt1,
  PrintTwoTone as PrintIcon,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { EditIcon } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button as CustomButton } from "../components/button";
import { Header } from "../components/header";
import {
  severityColorPalette,
  timestampToDaytime,
  titleCase,
} from "../helpers";
import { hospitalData } from "../opd/PatientCommandCenter";
import { Hospital } from "../opd/models";
import { ActionModal, TActionMode } from "./actionModal";
import DoctorStatusUpdateModal, {
  DOCTOR_STATUS,
} from "./doctorStatusUpdateModal";
import {
  fetchOPDEscalations,
  IEscalationData,
  IEscalationDataToBeUpdated,
  TEscalationAction,
  TPatientAction,
  updateEscalationsStatus,
} from "./fetchEscalations";

export interface IDoctorEscalationData {
  doctor_id: string;
  doctor_delay_duration: number;
  escalation_action: TEscalationAction | null;
}

export interface IUpdatedPatientActionData {
  appointment_id: string;
  patient_action_taken: TPatientAction | null;
}

const EscalationView = () => {
  const navigate = useNavigate();

  const [showDoctorStatusUpdateModal, setShowDoctorStatusUpdateModal] =
    useState(false);
  const [actionModalState, setActionModalState] = useState<{
    show: boolean;
    mode: TActionMode;
  }>({ show: false, mode: "add" });

  const [escalationData, setEscalationData] = useState<IEscalationData[]>([]);
  const [latestEscalationTime, setLatestEscalationTime] = useState("");

  const [hideAcknowledgedAppointments, setHideAcknowledgedAppointments] =
    useState(false);

  const [doctorEscalationData, setDoctorEscalationData] =
    useState<IDoctorEscalationData | null>(null);

  console.log(doctorEscalationData);

  const [selectedRowData, setSelectedRowData] =
    useState<IEscalationData | null>(null);

  const [selectedHospital, setSelectedHospital] = useState<Hospital | null>(
    null
  );

  const handlePrint = useCallback(() => {
    // TODO: write function to print here
  }, []);

  const handleCSVExport = useCallback(() => {
    // TODO: write function to export CSV file here
  }, []);

  const handleActionButtonSubmit = useCallback(
    (data: IUpdatedPatientActionData[]) => {
      const updatedEscalationData: IEscalationDataToBeUpdated = {
        escalation_action: doctorEscalationData?.escalation_action ?? null,
        doctor_id: String(selectedRowData?.doctor_id),
        doctor_delay_duration:
          doctorEscalationData?.escalation_action === "DOCTOR_DELAY"
            ? Number(doctorEscalationData?.doctor_delay_duration)
            : null,
        appointments: data,
      };

      updateEscalationsStatus(updatedEscalationData).then(() => {
        fetchOPDEscalations(
          selectedHospital?.id ?? "9757a411-668d-4261-bf93-bb97132cde6a"
        ).then((data) => {
          setEscalationData(
            data.escalations.filter(
              (e) => e.escalation_level !== "NO_ESCALATION"
            )
          );
          setLatestEscalationTime(timestampToDaytime(data.latest_updated_time));
        });
      });
    },
    [
      doctorEscalationData?.doctor_delay_duration,
      doctorEscalationData?.escalation_action,
      selectedHospital?.id,
      selectedRowData?.doctor_id,
    ]
  );

  const handleDoctorNotReachableSubmit = useCallback(
    (d: IDoctorEscalationData) => {
      const updatedEscalationData: IEscalationDataToBeUpdated = {
        escalation_action: d.escalation_action ?? "DOCTOR_DELAY",
        doctor_id: String(d?.doctor_id),
        doctor_delay_duration: Number(d?.doctor_delay_duration),
        appointments: [],
      };

      updateEscalationsStatus(updatedEscalationData).then(() => {
        fetchOPDEscalations(
          selectedHospital?.id ?? "9757a411-668d-4261-bf93-bb97132cde6a"
        ).then((data) => {
          setEscalationData(
            data.escalations.filter(
              (e) => e.escalation_level !== "NO_ESCALATION"
            )
          );
          setLatestEscalationTime(timestampToDaytime(data.latest_updated_time));
        });
      });
    },
    [selectedHospital?.id]
  );

  const acknowledgedEscalations = useMemo(
    () => escalationData.filter((e) => e.acknowledged === true),
    [escalationData]
  );

  const escAction = useMemo(() => {
    return (
      selectedRowData?.escalations.find((esc) => esc.escalation_action !== null)
        ?.escalation_action ?? null
    );
  }, [selectedRowData]);

  const handleBackToDoctorView = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleRowSelect = useCallback((d: any) => {
    setSelectedRowData(d);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const branchId =
      urlParams.get("branch_id") || "9757a411-668d-4261-bf93-bb97132cde6a";

    const hospital =
      hospitalData.find((h) => h.id === branchId) || hospitalData[0];

    fetchOPDEscalations(
      hospital.id ?? "9757a411-668d-4261-bf93-bb97132cde6a"
    ).then((data) => {
      setEscalationData(
        data.escalations.filter((e) => e.escalation_level !== "NO_ESCALATION")
      );

      setLatestEscalationTime(timestampToDaytime(data.latest_updated_time));
    });
    setSelectedHospital(hospital);
  }, []);

  const handleOpenActionModal = useCallback(
    ({
      rowData,
      mode = "add",
    }: {
      rowData: IEscalationData;
      mode?: TActionMode;
    }) => {
      setDoctorEscalationData({
        doctor_delay_duration: Number(rowData.doctor_delay_duration),
        doctor_id: String(rowData.doctor_id),
        escalation_action:
          rowData.escalations.find((e) => e.escalation_action !== null)
            ?.escalation_action ?? "DOCTOR_DELAY",
      });

      setActionModalState({ show: true, mode });
    },
    []
  );

  const handleCloseActionModal = useCallback(() => {
    setActionModalState({ show: false, mode: "add" });
  }, []);

  const handleNextButtonInStatusModal = useCallback(
    (d: Omit<IDoctorEscalationData, "doctor_id">) => {
      setDoctorEscalationData({
        doctor_delay_duration: d.doctor_delay_duration,
        escalation_action: d.escalation_action,
        doctor_id: String(selectedRowData?.doctor_id),
      });

      d.escalation_action !== "DOCTOR_NOT_REACHABLE"
        ? setActionModalState({ mode: "replace", show: true })
        : handleDoctorNotReachableSubmit({
            doctor_delay_duration: d.doctor_delay_duration,
            escalation_action: d.escalation_action,
            doctor_id: String(selectedRowData?.doctor_id),
          });
    },
    [handleDoctorNotReachableSubmit, selectedRowData?.doctor_id]
  );

  const handleCustomButtonHandle = useCallback(
    (
      e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
      data: IEscalationData
    ) => {
      e.preventDefault();
      e.stopPropagation();

      const actionTaken =
        data.escalations.find((e) => e.escalation_action !== null)
          ?.escalation_action ?? null;

      handleRowSelect(data);

      actionTaken === "DOCTOR_NOT_REACHABLE"
        ? handleOpenActionModal({ rowData: data, mode: "view" })
        : handleOpenActionModal({ rowData: data });
    },
    [handleOpenActionModal, handleRowSelect]
  );

  if (selectedHospital?.id !== "05478906-a974-4f78-84a2-38ce85c39206") {
    return (
      <div className="bg-black text-white min-h-screen p-2 sm:p-4 lg:p-6 flex items-center justify-center text-lg">
        You are not authorized to access this page
      </div>
    );
  }

  return (
    <div className="bg-black text-white min-h-screen p-2 sm:p-4 lg:p-6">
      <Header className="bg-gray-900">
        <Header.Title icon={HospitalIcon}>
          {selectedHospital?.name || "Select Hospital"}
        </Header.Title>

        <Button
          variant="contained"
          size="small"
          className="!font-bold"
          startIcon={<ArrowBack />}
          onClick={handleBackToDoctorView}
        >
          Back to Doctor View
        </Button>
      </Header>

      <DoctorStatusUpdateModal
        open={showDoctorStatusUpdateModal}
        onClose={() => {
          setShowDoctorStatusUpdateModal(false);
        }}
        doctorName={selectedRowData?.doctor_name ?? ""}
        handleNextButton={handleNextButtonInStatusModal}
        defaultValues={{
          doctor_delay_duration: selectedRowData?.doctor_delay_duration ?? 0,
          escalation_action: escAction,
        }}
      />

      <ActionModal
        mode={actionModalState.mode}
        open={actionModalState.show}
        doctorStatus={
          doctorEscalationData?.escalation_action ?? escAction ?? "DOCTOR_DELAY"
        }
        delayedTime={doctorEscalationData?.doctor_delay_duration ?? null}
        onClose={handleCloseActionModal}
        avgWaitTime={selectedRowData?.avg_wait_time ?? 0}
        doctorName={selectedRowData?.doctor_name ?? ""}
        handleSubmitButton={handleActionButtonSubmit}
        escalatedAppointmentData={selectedRowData}
      />

      {
        <div className="flex flex-col bg-gray-900 rounded-lg py-4 px-6 shadow-lg">
          <div className="flex items-center justify-between p-2 mb-2 w-full">
            <div className="flex items-center gap-10">
              <div className="flex gap-2">
                <PersonAddAlt1 />

                <span>Backlogged Doctors ({escalationData.length}) </span>
              </div>

              <div className="flex gap-2">
                Escalation Time: {latestEscalationTime}
              </div>
            </div>

            <div className="flex gap-2 items-center">
              <IconButton
                onClick={handlePrint}
                className="text-white mr-2 !border !border-white rounded-lg"
              >
                <PrintIcon color="primary" />
              </IconButton>

              <IconButton onClick={handleCSVExport} className="text-white">
                <GridIcon color="primary" />
              </IconButton>
            </div>
          </div>

          <table className="w-full min-w-full !rounded-lg overflow-hidden divide-y">
            <thead className="bg-[#11141e]">
              <tr>
                <th
                  scope="col"
                  colSpan={3}
                  className="px-6 py-2.5 text-left text-sm font-bold text-gray-300 uppercase tracking-wider rounded-tl-lg"
                >
                  Doctor
                </th>

                <th
                  scope="col"
                  colSpan={1}
                  className="px-6 py-2.5 text-sm text-center font-bold text-gray-300 uppercase tracking-wider rounded-tl-lg"
                >
                  Level
                </th>

                <th
                  scope="col"
                  colSpan={2}
                  className="px-3 py-2.5 text-center text-sm whitespace-nowrap text-gray-300 uppercase tracking-wider font-bold"
                >
                  Avg. Wait Time (in mins)
                </th>

                <th
                  scope="col"
                  colSpan={1}
                  className="px-3 py-2.5 text-center text-sm font-bold text-gray-300 whitespace-nowrap uppercase tracking-wider"
                >
                  Wait Count
                </th>

                <th
                  colSpan={3}
                  scope="col"
                  className="px-6 py-2.5 text-sm  text-left text-gray-300 uppercase tracking-wider font-bold rounded-tr-lg"
                >
                  Action Taken
                </th>

                <th
                  colSpan={1}
                  scope="col"
                  className="px-6 py-2.5 text-sm  text-left text-gray-300 uppercase tracking-wider font-bold rounded-tr-lg  w-[80px] max-w-[80px]"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-gray-700 divide-y box-border">
              {escalationData
                .filter((esc) => !esc.acknowledged)
                .map((data, i) => {
                  const escAction =
                    data.escalations.find(
                      (esc) => esc.escalation_action !== null
                    )?.escalation_action ?? undefined;

                  const isPatientActionTaken = data.escalations.some(
                    (esc) => esc.patient_action_taken !== null
                  );

                  return (
                    <tr
                      key={i}
                      className={`text-white text-sm group bg-gray-800 hover:bg-gray-700`}
                      onClick={() => {
                        handleRowSelect(data);
                        handleOpenActionModal({ rowData: data, mode: "view" });
                      }}
                    >
                      <td
                        className="group-last:rounded-bl-lg px-6 py-1 whitespace-nowrap text-sm w-[300px] max-w-[300px]"
                        colSpan={3}
                      >
                        {titleCase(data.doctor_name)}
                      </td>

                      <td
                        colSpan={1}
                        className={`px-3 py-2 whitespace-nowrap text-center self-center justify-center text-sm font-semibold `}
                      >
                        <div
                          className={`max-w-[94px] w-[94px] px-3 py-1  text-white rounded-full inline-block bg-opacity-50   border-2 
                             ${severityColorPalette[data.escalation_level]}`}
                        >
                          {data.escalation_level !== "NO_ESCALATION"
                            ? data.escalation_level
                            : "NE"}
                        </div>
                      </td>

                      <td
                        colSpan={2}
                        className={`px-3 py-2 whitespace-nowrap text-center self-center justify-center text-sm font-semibold `}
                      >
                        {data.avg_wait_time}
                      </td>

                      <td
                        className="px-3 py-1 whitespace-nowrap text-center"
                        colSpan={1}
                      >
                        {
                          data.escalations.filter(
                            (esc) => esc.patient_checkedin_time !== null
                          ).length
                        }
                      </td>

                      <td
                        colSpan={3}
                        className="px-6 py-1 whitespace-nowrap self-left w-[300px] max-w-[300px]"
                      >
                        <div className="flex justify-start gap-2">
                          {escAction === "DOCTOR_NOT_REACHABLE" ||
                          (isPatientActionTaken && escAction !== undefined) ? (
                            <>
                              <CustomButton
                                bold={false}
                                buttonType="green"
                                className="max-w-max justify-center !self-center !px-2 !rounded-md"
                                onClick={(e) =>
                                  handleCustomButtonHandle(e, data)
                                }
                              >
                                {
                                  DOCTOR_STATUS.find(
                                    (d) => d.value === escAction
                                  )?.label
                                }

                                {data.escalations.find(
                                  (esc) => esc.patient_action_taken !== null
                                )?.escalation_action === "DOCTOR_DELAY"}
                              </CustomButton>

                              {escAction === "DOCTOR_DELAY" && (
                                <CustomButton
                                  bold={false}
                                  buttonType="green"
                                  className=" max-w-max justify-center !self-center !px-2 !rounded-md"
                                  onClick={(e) =>
                                    handleCustomButtonHandle(e, data)
                                  }
                                >
                                  {data.doctor_delay_duration + " mins"}
                                </CustomButton>
                              )}
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              color="info"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRowSelect(data);
                                setShowDoctorStatusUpdateModal(true);
                              }}
                            >
                              Take Action
                            </Button>
                          )}
                        </div>
                      </td>

                      <td className="px-6 py-1 group-last:rounded-br-lg w-[80px] max-w-[80px]">
                        {escAction !== "DOCTOR_LEAVE" &&
                          escAction !== undefined && (
                            <EditIcon
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRowSelect(data);
                                setShowDoctorStatusUpdateModal(true);
                              }}
                            />
                          )}
                      </td>
                    </tr>
                  );
                })}

              {acknowledgedEscalations.length !== 0 && (
                <tr
                  className="bg-[#69dd5e] text-white hover:bg-opacity-75 bg-opacity-65 text-center cursor-pointer"
                  onClick={() =>
                    setHideAcknowledgedAppointments((prev) => !prev)
                  }
                >
                  <td
                    colSpan={11}
                    className="px-6 py-2 whitespace-nowrap text-sm font-semibold items-center align-middle"
                  >
                    <div className="inline-flex items-center justify-center gap-2">
                      ACKNOWLEDGED
                      <CheckCircleOutlineOutlined />
                    </div>

                    {hideAcknowledgedAppointments ? (
                      <ExpandLess className="!float-end" />
                    ) : (
                      <ExpandMore className="!float-end" />
                    )}
                  </td>
                </tr>
              )}

              {!hideAcknowledgedAppointments &&
                acknowledgedEscalations.map((data, i) => {
                  const escAction =
                    data.escalations.find(
                      (esc) => esc.escalation_action !== null
                    )?.escalation_action ?? undefined;

                  return (
                    <tr
                      key={i}
                      className={`bg-gray-800  hover:bg-gray-700`}
                      onClick={() => {
                        handleRowSelect(data);
                        handleOpenActionModal({ rowData: data, mode: "view" });
                      }}
                    >
                      <td
                        className="group-last:rounded-bl-lg px-6 py-1 whitespace-nowrap text-sm w-[300px] max-w-[300px]"
                        colSpan={3}
                      >
                        {titleCase(data.doctor_name)}
                      </td>

                      {/* <td className="px-6 py-1 whitespace-nowrap text-center">
                    {data.room}
                  </td> */}

                      <td
                        colSpan={1}
                        className={`px-3 py-2 whitespace-nowrap text-center self-center justify-center text-sm font-semibold `}
                      >
                        <div
                          className={`max-w-[94px] px-3 py-1 rounded-full inline-block w-[94px] bg-opacity-50 border-2 ${
                            severityColorPalette[data.escalation_level]
                          }`}
                        >
                          {data.escalation_level !== "NO_ESCALATION"
                            ? data.escalation_level
                            : "NE"}
                        </div>
                      </td>

                      <td
                        colSpan={2}
                        className={`px-3 py-2 whitespace-nowrap text-center self-center justify-center text-sm font-semibold `}
                      >
                        {data.avg_wait_time}
                      </td>

                      <td
                        className="px-6 py-1 whitespace-nowrap text-center"
                        colSpan={1}
                      >
                        {
                          data.escalations.filter(
                            (esc) => esc.patient_checkedin_time !== null
                          ).length
                        }
                      </td>

                      <td
                        colSpan={3}
                        className="px-6 py-1 whitespace-nowrap self-left w-[300px] max-w-[300px] group-last:rounded-br-lg"
                      >
                        <div className="flex justify-start gap-2">
                          {data.escalations.some(
                            (esc) => esc.patient_action_taken !== null
                          ) && escAction !== undefined ? (
                            <>
                              <CustomButton
                                bold={false}
                                buttonType="green"
                                className="max-w-max justify-center !self-center !px-2 !rounded-md"
                                onClick={(e) =>
                                  handleCustomButtonHandle(e, data)
                                }
                              >
                                {
                                  DOCTOR_STATUS.find(
                                    (d) => d.value === escAction
                                  )?.label
                                }

                                {escAction === "DOCTOR_DELAY"}
                              </CustomButton>

                              {escAction === "DOCTOR_DELAY" && (
                                <CustomButton
                                  bold={false}
                                  buttonType="green"
                                  className=" max-w-max justify-center !self-center !px-2 !rounded-md"
                                  onClick={(e) =>
                                    handleCustomButtonHandle(e, data)
                                  }
                                >
                                  {data.doctor_delay_duration + " mins"}
                                </CustomButton>
                              )}
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              color="info"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRowSelect(data);
                                setShowDoctorStatusUpdateModal(true);
                              }}
                            >
                              Take Action
                            </Button>
                          )}
                        </div>
                      </td>

                      <td className="px-6 py-1 group-last:rounded-br-lg w-[80px] max-w-[80px]">
                        {escAction !== undefined &&
                          escAction !== "DOCTOR_LEAVE" && (
                            <EditIcon
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRowSelect(data);
                                setShowDoctorStatusUpdateModal(true);
                              }}
                            />
                          )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
};

export default EscalationView;
