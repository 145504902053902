import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import EscalationView from "./escalation";
import ExecutiveDashboard from "./executive_view/ExecutiveDashboard";
import IPDExecutiveDashboard from "./executive_view/ExecutiveIPDDashboard";
import FinanceDashboard from "./financial/FinanceDashboard";
import "./index.css";
import HospitalIPDView from "./ipd/IPDHospitalView";
import Login from "./login";
import CombinedCommandCenter from "./opd/CommandCenter";
import ProtectedRoute from "./protectedRoute";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          }
        />
        <Route
          path="/opd"
          element={
            <ProtectedRoute>
              <CombinedCommandCenter />
            </ProtectedRoute>
          }
        />

        <Route
          path="/opd/escalation"
          element={
            <ProtectedRoute>
              <EscalationView />
            </ProtectedRoute>
          }
        />

        <Route
          path="/ipd"
          element={
            <ProtectedRoute>
              <HospitalIPDView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/executive"
          element={
            <ProtectedRoute>
              <ExecutiveDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ipd-executive"
          element={
            <ProtectedRoute>
              <IPDExecutiveDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/app-finance"
          element={
            <ProtectedRoute>
              <FinanceDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
