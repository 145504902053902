// Define the base DailyMetricInput interface
export interface DailyMetricInput {
    date: string;
    app_leads?: number;
    cart_orders?: number;
    call_leads_converted?: number;
    mah_digicare_leads?: number;
    mah_cc_leads?: number;
    app_revenue?: number;
    overall_revenue?: number;
    app_packages?: number;
    overall_packages?: number;
    app_profiles?: number;
    overall_profiles?: number;
    in_hospital_order_count?: number;
    in_hospital_revenue?: number;
    cart_converted?: number;
    app_lead_converted?: number;
    call_leads_converted_count?: number;
    cart_order_count?: number;
    converted_orders?: number;
    booked_revenue?: number;
    realized_revenue?: number;
    call_leads_realized_revenue?: number;
    avg_cart_item_count_app?: number;
    avg_cart_item_count_overall?: number;
}

// Define the DailyMetricOutput interface that extends DailyMetricInput
export interface DailyMetricOutput extends DailyMetricInput {
    id: number;
    total_leads?: number;
    mah_total_leads?: number;
    app_percentage_overall?: number;
    app_percentage_cc_leads?: number;
    app_aov?: number;
    overall_aov?: number;
    app_package_percentage?: number;
    overall_package_percentage?: number;
    app_profile_percentage?: number;
    overall_profile_percentage?: number;
    in_hospital_aov?: number;
    cart_conversion_percentage?: number;
    app_lead_conversion_percentage?: number;
    call_leads_conversion_percentage?: number;
    overall_path_revenue?: number;
    app_contribution_percentage?: number;
}

export const metricFields: Array<{ key: keyof DailyMetricInput, label: string, type: string }> = [
    { key: 'date', label: 'Date', type: 'date' },
    { key: 'app_leads', label: 'App Leads', type: 'number' },
    { key: 'cart_orders', label: 'Cart Orders', type: 'number' },
    { key: 'call_leads_converted', label: 'Call Leads Converted', type: 'number' },
    { key: 'mah_digicare_leads', label: 'MAH DigiCare Leads', type: 'number' },
    { key: 'mah_cc_leads', label: 'MAH CC Leads', type: 'number' },
    { key: 'app_revenue', label: 'App Revenue', type: 'number' },
    { key: 'overall_revenue', label: 'Overall Revenue', type: 'number' },
    { key: 'app_packages', label: 'App Packages', type: 'number' },
    { key: 'overall_packages', label: 'Overall Packages', type: 'number' },
    { key: 'app_profiles', label: 'App Profiles', type: 'number' },
    { key: 'overall_profiles', label: 'Overall Profiles', type: 'number' },
    { key: 'in_hospital_order_count', label: 'In-Hospital Order Count', type: 'number' },
    { key: 'in_hospital_revenue', label: 'In-Hospital Revenue', type: 'number' },
    { key: 'cart_converted', label: 'Cart Converted', type: 'number' },
    { key: 'app_lead_converted', label: 'App Lead Converted', type: 'number' },
    { key: 'call_leads_converted_count', label: 'Call Leads Converted Count', type: 'number' },
    { key: 'cart_order_count', label: 'Cart Order Count', type: 'number' },
    { key: 'converted_orders', label: 'Converted Orders', type: 'number' },
    { key: 'booked_revenue', label: 'Booked Revenue', type: 'number' },
    { key: 'realized_revenue', label: 'Realized Revenue', type: 'number' },
    { key: 'call_leads_realized_revenue', label: 'Call Leads Realized Revenue', type: 'number' },
    { key: 'avg_cart_item_count_app', label: 'Avg Cart Item Count (App)', type: 'number' },
    { key: 'avg_cart_item_count_overall', label: 'Avg Cart Item Count (Overall)', type: 'number' },
];
