import { IPDDataResponse, IPDExecutiveDataResponse, IPDPatient } from "./models";


export const fetchExecutiveData = async (): Promise<IPDExecutiveDataResponse[]> => {

    // const url = `http://localhost:8000/get-opd-data?serviceId=${serviceTypeId}&branchId=${branchId}`;
    const url = `https://analytics-beta.maxhealthcaredigital.com/get-ipd-data-executive`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: IPDExecutiveDataResponse[] = await response.json();
        console.log('Data fetched:', data);

        return data;

    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchHospitalIPDData = async (hospitalId: string): Promise<IPDDataResponse> => {
    const url = `https://analytics-beta.maxhealthcaredigital.com/get-ipd-data?hospital_id=${hospitalId}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: IPDDataResponse = await response.json();
        console.log('Data fetched:', data);

        return data;

    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const fetchWardIPDData = async (hospitalId: string, wardName: string): Promise<IPDPatient[]> => {
    const url = `https://analytics-beta.maxhealthcaredigital.com/get-ipd-data-by-ward?hospital_id=${hospitalId}&ward_number=${wardName}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: IPDPatient[] = await response.json();
        console.log('Data fetched:', data);

        return data;

    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}