import { SvgIconComponent } from "@mui/icons-material";
import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

interface ITitleProps {
  id?: string;
  icon?: SvgIconComponent;
  children?: React.ReactNode;
  onClick?: () => void;
}

const Title = ({
  id,
  children,
  onClick,
  icon: Icon,
}: ITitleProps): JSX.Element => {
  return (
    <h1
      id={id}
      className={`text-xl sm:text-2xl lg:text-3xl font-bold flex gap-2 items-center ${
        onClick ? "cursor-pointer" : ""
      }
       `}
      onClick={onClick}
    >
      {Icon && <Icon />}
      {children}
    </h1>
  );
};

interface IHeaderToggleProps {
  checked: boolean;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, d: boolean) => void;
}

const HeaderToggle = ({ checked, label, onChange }: IHeaderToggleProps) => {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} color="primary" />}
      label={label}
    />
  );
};

interface IHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const Header = ({ children, className }: IHeaderProps) => {
  return (
    <header
      className={`bg-gray-800 p-3 sm:p-4 rounded-lg shadow-lg mb-4 flex justify-between items-center sm:mb-6 ${
        className ? className : ""
      }`}
    >
      {children}
    </header>
  );
};

Header.Title = Title;
Header.Toggle = HeaderToggle;

export { Header };
