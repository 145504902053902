import { ArrowForward, Cancel } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IDoctorEscalationData } from ".";
import { titleCase } from "../helpers";
import { TEscalationAction } from "./fetchEscalations";

interface IDoctorStatusUpdateModalProps {
  open: boolean;
  defaultValues?: Omit<IDoctorEscalationData, "doctor_id">;
  onClose: () => void;
  handleNextButton: (d: Omit<IDoctorEscalationData, "doctor_id">) => void;
  doctorName: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      background: "#31363F",
    },
  },
};

export const DOCTOR_STATUS: Array<{ label: string; value: TEscalationAction }> =
  [
    { label: "Doctor Delayed", value: "DOCTOR_DELAY" },
    { label: "Doctor On Leave", value: "DOCTOR_LEAVE" },
    { label: "Manual Prescription", value: "MANUAL_PRESCRIPTION" },
    { label: "Doctor Not Reachable", value: "DOCTOR_NOT_REACHABLE" },
  ];

const DoctorStatusUpdateModal = ({
  open,
  onClose,
  doctorName,
  defaultValues,
  handleNextButton,
}: IDoctorStatusUpdateModalProps) => {
  const [selectedStatus, setSelectedStatus] = React.useState<{
    label: string;
    value: TEscalationAction;
  } | null>(
    DOCTOR_STATUS.find((d) => d.value === defaultValues?.escalation_action) ??
      null
  );

  const [delayedTime, setDelayedTime] = useState<number | null>(null);

  const handleChange = (event: SelectChangeEvent<TEscalationAction>) => {
    const {
      target: { value },
    } = event;

    setSelectedStatus(DOCTOR_STATUS.find((d) => d.value === value) ?? null);
  };

  const isSubmitDisabled = useMemo(() => {
    return (
      selectedStatus === null ||
      (selectedStatus?.value === "DOCTOR_DELAY" &&
        (delayedTime === null || delayedTime < 1))
    );
  }, [delayedTime, selectedStatus]);

  const handleOnClose = useCallback(() => {
    onClose?.();
    setSelectedStatus(null);
    setDelayedTime(null);
  }, [onClose]);

  const handleSubmitButton = useCallback(() => {
    handleOnClose?.();

    handleNextButton?.({
      escalation_action: selectedStatus?.value as TEscalationAction,
      doctor_delay_duration: Number(delayedTime),
    });
  }, [delayedTime, handleNextButton, handleOnClose, selectedStatus?.value]);

  useEffect(() => {
    setSelectedStatus(
      DOCTOR_STATUS.find((d) => d.value === defaultValues?.escalation_action) ??
        null
    );

    setDelayedTime(defaultValues?.doctor_delay_duration ?? null);
  }, [defaultValues?.doctor_delay_duration, defaultValues?.escalation_action]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      maxWidth="md"
      className="rounded-lg"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#EEEEEE",
          borderRadius: "8px",
          color: "black",
          boxShadow: "0 0 10px rgba(255, 255, 255, 0.1)",
          filter: "none", // Ensure the popup is not blurred
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center bg-[#111827] text-white">
        <span className="text-lg font-semibold">Doctor Status Update</span>

        <div>
          <IconButton
            onClick={handleOnClose}
            size="small"
            style={{ color: "white" }}
          >
            <Cancel />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className="flex items-center justify-center flex-col !px-3 bg-[#12141e] !py-6 gap-3 ">
        <p className="text-white text-md">
          Doctor Name:{" "}
          <span className="font-bold mx-1">{titleCase(doctorName)}</span>
        </p>

        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="select-doctor-status-label" className="!text-white">
            Select Doctor Status
          </InputLabel>

          <Select
            labelId="select-doctor-status-label"
            id="demo-multiple-chip"
            required
            value={
              (selectedStatus?.value as TEscalationAction) ??
              defaultValues?.escalation_action
            }
            onChange={handleChange}
            input={
              <OutlinedInput
                id="select-multiple-chip"
                label="Select Doctor Status"
              />
            }
            style={{
              color: "white",
              borderColor: "white",
            }}
            className="!border-white [&>fieldset]:!border-white [&>svg]:!text-white"
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selectedStatus?.label ??
                  DOCTOR_STATUS.find(
                    (d) => d.value === defaultValues?.escalation_action
                  )?.label}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {DOCTOR_STATUS.map((doctorStat) => (
              <MenuItem
                style={{
                  color: "white",
                  background: "#31363F",
                }}
                key={doctorStat.value}
                value={doctorStat.value}
              >
                {doctorStat.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {((selectedStatus === null &&
          defaultValues?.escalation_action === "DOCTOR_DELAY") ||
          selectedStatus?.value === "DOCTOR_DELAY") && (
          <FormControl sx={{ m: 1, width: 400 }}>
            <InputLabel
              id="select-doctor-delay-time-label"
              className="!text-white"
            >
              Time Delayed (in mins)
            </InputLabel>

            <Select
              labelId="select-doctor-status-label"
              id="demo-multiple-chip"
              required={selectedStatus?.value === "DOCTOR_DELAY"}
              defaultValue={defaultValues?.doctor_delay_duration ?? undefined}
              value={delayedTime ?? undefined}
              onChange={(e) => {
                setDelayedTime(parseInt(String(e.target.value)));
              }}
              className="!border-white [&>fieldset]:!border-white  [&>svg]:!text-white"
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Time Delayed (in mins)"
                />
              }
              style={{
                color: "white",
              }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {selected + " mins"}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120].map(
                (time) => (
                  <MenuItem
                    style={{
                      color: "white",
                      background: "#31363F",
                    }}
                    key={time}
                    value={time}
                  >
                    {time + " mins"}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        )}

        <IconButton
          onClick={isSubmitDisabled ? undefined : handleSubmitButton}
          size="large"
          style={{
            border: "2px solid white",
            boxShadow: "1px 3px 6px rgba(240, 240, 240, 0.2)",
            background: "#0275ce",
          }}
          className="text-white !border-0 !border-white rounded-lg disabled:!bg-[#2764bb] disabled:opacity-65 transition-all ease-in-out delay-200 hover:transform hover:scale-110"
          disabled={isSubmitDisabled}
        >
          <ArrowForward style={{ color: "white" }} />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default DoctorStatusUpdateModal;
