type TTextTag = "p" | "span";

interface ITextProps {
  as?: TTextTag;
  children: React.ReactNode;
  color?: TTextColors;
  size?: TTextSizes;
  bold?: boolean;
  className?: string;
}

export type TTextSizes = "small" | "medium" | "large";

export type TTextColors = "gray" | "light-gray" | "white" | "red";

export const COLORS: Record<TTextColors, string> = {
  gray: "text-[#2e3138]",
  "light-gray": "text-black text-opacity-50",
  white: "text-white",
  red: "text-new-red",
};

const SIZES: Record<TTextSizes, string> = {
  small: "text-sm",
  medium: "text-md",
  large: "text-large",
};

export const Text = ({
  as: TextType = "p",
  children,
  className,
  color = "gray",
  size = "medium",
  bold = false,
  ...props
}: ITextProps): JSX.Element => {
  return (
    <TextType
      {...props}
      className={`font-inter truncate ${COLORS[color]} ${
        bold && "font-[500]"
      } ${SIZES[size]} ${className}`}
    >
      {children}
    </TextType>
  );
};
