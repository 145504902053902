import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, PieLabelRenderProps } from 'recharts';
import { AccessTime, LocalHospital, People, AttachMoney } from '@mui/icons-material';
import { IPDDataResponse, IPDPatient } from './models';
import { fetchHospitalIPDData, fetchWardIPDData } from './fetchData';
import { getEmergencyStayTimeClass, getIPDStayTimeClass } from '../executive_view/ExecutiveIPDDashboard';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF1919', '#19FFB1', '#FF19E7'];

const formatTime = (minutes: number): string => {
    const modifiedMinutes = Math.floor(minutes);
    const hours = Math.floor(modifiedMinutes / 60);
    const remainingMinutes = modifiedMinutes % 60;
    return `${hours}h ${remainingMinutes}m`;
};

const toTitleCase = (str: string): string => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const getIPDOccupancyClass = (occupancy: number): string => {
    if (occupancy > 85) return 'text-purple-500';
    if (occupancy > 70) return 'text-green-500';
    if (occupancy > 50) return 'text-yellow-500';
    return 'text-red-500';
};

const CustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: PieLabelRenderProps) => {
    if (typeof cx !== 'number' || typeof cy !== 'number' || typeof innerRadius !== 'number' ||
        typeof outerRadius !== 'number' || typeof midAngle !== 'number' || typeof percent !== 'number') {
        return null;
    }

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

// Create a dark theme for the Dialog
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            paper: '#1F2937', // This matches the bg-gray-900 from Tailwind
        },
    },
});

const HospitalIPDView: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const hospital_id = queryParams.get('branch_id');
    const [data, setData] = useState<IPDDataResponse | null>(null);
    const [selectedWard, setSelectedWard] = useState<string | null>(null);
    const [wardPatients, setWardPatients] = useState<IPDPatient[]>([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!hospital_id) {
                console.error('Hospital ID not provided');
                return;
            }

            try {
                const response = await fetchHospitalIPDData(hospital_id);
                setData(response);
            } catch (error) {
                console.error('Error fetching hospital IPD data:', error);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, [hospital_id]);

    const handleWardClick = async (wardName: string) => {
        if (!hospital_id) return;

        setIsLoading(true);
        setError(null);
        setIsPopupOpen(true);
        setSelectedWard(wardName);

        try {
            const patients = await fetchWardIPDData(hospital_id, wardName);
            setWardPatients(patients);
        } catch (error) {
            console.error('Error fetching ward IPD data:', error);
            setError('Failed to fetch ward data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedWard(null);
        setWardPatients([]);
        setError(null);
    };

    if (!data) {
        return <div className="flex justify-center items-center h-screen text-white">Loading...</div>;
    }

    let occupancyPercent = Math.floor((data.ipd_count / data.total_bed_count) * 100);

    return (
        <div className="bg-black text-white min-h-screen p-4">
            <h1 className="text-3xl font-bold mb-6 flex items-center">
                <LocalHospital className="mr-2" /> {`${data.hospital_name} IPD Dashboard`}
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* IPD Summary */}
                <div className="bg-gray-900 rounded-lg p-4 shadow-lg col-span-2">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                        <LocalHospital className="mr-2" /> IPD Summary
                    </h2>
                    <div className="grid grid-cols-4 gap-4">
                        <div>
                            <p className="text-sm">Occupancy</p>
                            <p className="text-2xl font-bold">{data.ipd_count} / {data.total_bed_count}</p>
                        </div>
                        <div>
                            <p className="text-sm">Occupancy %</p>
                            <p className={`text-2xl font-bold ${getIPDOccupancyClass(occupancyPercent)}`}>{`${occupancyPercent} %`}</p>
                        </div>
                        <div>
                            <p className="text-sm">Avg Length of Stay</p>
                            <p className={`text-2xl font-bold ${getIPDStayTimeClass(data.ipd_avg_length_of_stay)}`}>{data.ipd_avg_length_of_stay.toFixed(1)} days</p>
                        </div>
                        <div>
                            <p className="text-sm">Planned admissions</p>
                            <p className="text-2xl font-bold">{data.arf_count}</p>
                        </div>
                    </div>
                </div>

                {/* Emergency */}
                <div className="bg-gray-900 rounded-lg p-4 shadow-lg">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                        <AccessTime className="mr-2" /> Emergency
                    </h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="text-sm">Occupancy</p>
                            <p className="text-2xl font-bold">{data.emergency_count}</p>
                        </div>
                        <div>
                            <p className="text-sm">Avg Length of Stay</p>
                            <p className={`text-2xl font-bold ${getEmergencyStayTimeClass(data.emergency_avg_length_of_stay)}`}>{formatTime(data.emergency_avg_length_of_stay)}</p>
                        </div>
                    </div>
                </div>

                {/* Ward Level Occupancy section */}
                <div className="bg-gray-900 rounded-lg p-4 shadow-lg col-span-2">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                        <LocalHospital className="mr-2" /> Ward Level Occupancy
                    </h2>
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm">
                            <thead>
                                <tr className="text-left">
                                    <th className="p-2">Ward</th>
                                    <th className="p-2">Admitted</th>
                                    <th className="p-2">Discharged Today</th>
                                    <th className="p-2">Discharge planned</th>
                                    <th className="p-2">ALOS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.ward_level_data.map((ward, index) => (
                                    <tr
                                        key={index}
                                        className={`${index % 2 === 0 ? 'bg-gray-800' : ''} cursor-pointer hover:bg-gray-700`}
                                        onClick={() => handleWardClick(ward.ward_name)}
                                    >
                                        <td className="p-2">{ward.ward_name}</td>
                                        <td className="p-2">{ward.admission_count}</td>
                                        <td className="p-2">{ward.discharge_count}</td>
                                        <td className="p-2">{ward.discharge_planned_count}</td>
                                        <td className={`p-2 ${getIPDStayTimeClass(ward.average_length_of_stay)}`}>{ward.average_length_of_stay.toFixed(1)} days</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* Category Split */}
                <div className="bg-gray-900 rounded-lg p-4 shadow-lg row-span-2">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                        <People className="mr-2" /> IPD Category Split
                    </h2>
                    <ResponsiveContainer width="100%" height={200}>
                        <PieChart>
                            <Pie
                                data={data.category_wise_admissions}
                                dataKey="stat_value"
                                nameKey="stat_key"
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#8884d8"
                                labelLine={false}
                                label={CustomizedLabel}
                            >
                                {data.category_wise_admissions.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend layout="vertical" align="right" verticalAlign="middle" />
                        </PieChart>
                    </ResponsiveContainer>

                    {/* Cash Outstanding */}
                    <h2 className="text-xl font-bold mt-6 mb-4 flex items-center">
                        <AttachMoney className="mr-2" /> Cash Outstanding
                    </h2>
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm">
                            <thead>
                                <tr className="text-left">
                                    <th className="p-2">Patient Name</th>
                                    <th className="p-2">Room No</th>
                                    <th className="p-2">LOS</th>
                                    <th className="p-2">Outstanding Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.cash_patients.map((patient, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : ''}>
                                        <td className="p-2">{toTitleCase(patient.patient_name)}</td>
                                        <td className="p-2">{patient.room_number}</td>
                                        <td className={`p-2 ${getIPDStayTimeClass(patient.length_of_stay)}`}>{patient.length_of_stay} days</td>
                                        <td className="p-2 text-end">₹{Math.round(patient.outstanding_amount).toLocaleString('en-IN')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* Ward Details Popup with dark theme */}
            <ThemeProvider theme={darkTheme}>
                <Dialog
                    open={isPopupOpen}
                    onClose={handleClosePopup}
                    maxWidth="md"
                    fullWidth
                    PaperProps={{
                        style: { backgroundColor: '#1F2937' },
                    }}
                >
                    <DialogTitle className="text-white">{`${selectedWard} - IPD Patients`}</DialogTitle>
                    <DialogContent>
                        {isLoading ? (
                            <div className="flex justify-center items-center h-40">
                                <CircularProgress />
                            </div>
                        ) : error ? (
                            <div className="text-red-500 text-center py-4">{error}</div>
                        ) : (
                            <div className="overflow-x-auto max-h-96">
                                <table className="w-full text-sm text-white">
                                    <thead>
                                        <tr className="text-left bg-gray-800">
                                            <th className="p-2">Patient ID</th>
                                            <th className="p-2">Patient Name</th>
                                            <th className="p-2">Room Number</th>
                                            <th className="p-2">Length of Stay</th>
                                            <th className="p-2">Category</th>
                                            <th className="p-2">Outstanding Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {wardPatients.map((patient, index) => (
                                            <tr key={index} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'}>
                                                <td className="p-2">{patient.patient_id}</td>
                                                <td className="p-2">{toTitleCase(patient.patient_name)}</td>
                                                <td className="p-2">{patient.room_number}</td>
                                                <td className={`p-2 ${getIPDStayTimeClass(patient.length_of_stay)}`}>{patient.length_of_stay} days</td>
                                                <td className="p-2">{toTitleCase(patient.category)}</td>
                                                <td className="p-2 text-end">₹{Math.round(patient.outstanding_amount).toLocaleString('en-IN')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </div>
    );
};

export default HospitalIPDView;