import React from 'react';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { HospitalStats } from './ExecutiveDashboard';
import { DoctorAssignment, OPDChamberData } from '../opd/models';

const styles = StyleSheet.create({
  page: { flexDirection: 'column', backgroundColor: '#ffffff', padding: 30 },
  section: { marginBottom: 10 },
  title: { fontSize: 18, fontWeight: 'bold', marginBottom: 10 },
  subtitle: { fontSize: 8, marginBottom: 10 },
  table: { display: 'flex', width: 'auto', borderStyle: 'solid', borderWidth: 1, borderColor: '#bfbfbf' },
  tableRow: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#bfbfbf' },
  tableColHeader: { width: '20%', borderStyle: 'solid', borderRightWidth: 1, borderRightColor: '#bfbfbf', backgroundColor: '#f0f0f0', padding: 5 },
  tableCol: { width: '20%', borderStyle: 'solid', borderRightWidth: 1, borderRightColor: '#bfbfbf', padding: 5 },
  tableCellHeader: { margin: 'auto', marginTop: 5, fontSize: 10, fontWeight: 'bold' },
  tableCell: { margin: 'auto', marginTop: 5, fontSize: 8 },
  doctorAwayHeader: { width: '60%', borderStyle: 'solid', borderRightWidth: 1, borderRightColor: '#bfbfbf', backgroundColor: '#f0f0f0', padding: 5 },
});

const isRoomAssigned = (assignment: DoctorAssignment) => {
  return assignment.roomNumber && assignment.roomNumber !== 'NaN' && assignment.roomNumber !== 'null' && assignment.roomNumber !== 'N/A';
};

const calculateRoomAssignments = (opdChamberData: OPDChamberData | undefined) => {
  if (!opdChamberData || !opdChamberData.assignments) {
    return { roomAssigned: 0, roomNotAssigned: 0 };
  }
  const roomAssigned = opdChamberData.assignments.filter(isRoomAssigned).length;
  const roomNotAssigned = opdChamberData.assignments.length - roomAssigned;
  return { roomAssigned, roomNotAssigned };
};

const DoctorAwayPDF = ({ hospitals }: { hospitals: HospitalStats[] }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Doctor Away Summary Report</Text>
        <Text style={styles.subtitle}>{`Generated on: ${new Date().toLocaleString()}`}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Hospital Name</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Total Shift</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Doctor In</Text></View>
          <View style={styles.doctorAwayHeader}><Text style={styles.tableCellHeader}>Doctor Away</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}></View>
          <View style={styles.tableCol}></View>
          <View style={styles.tableCol}></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Room Assigned</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Room Not Assigned</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Total</Text></View>
        </View>
        {hospitals.map((hospital, index) => {
          const { roomAssigned, roomNotAssigned } = calculateRoomAssignments(hospital.opdData);
          const totalShift = (hospital.opdData?.doctorIn ?? 0) + (hospital.opdData?.doctorAway ?? 0);
          return (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{hospital.name.replace('Max', '')}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{totalShift}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{hospital.opdData?.doctorIn ?? 0}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{roomAssigned}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{roomNotAssigned}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{hospital.opdData?.doctorAway ?? 0}</Text></View>
            </View>
          );
        })}
      </View>
    </Page>
  </Document>
);

export const generateDoctorAwayPDF = async (hospitals: HospitalStats[]) => {
  const blob = await pdf(<DoctorAwayPDF hospitals={hospitals} />).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'doctor_away_summary.pdf';
  link.click();
  URL.revokeObjectURL(url);
};

export default DoctorAwayPDF;